<template>
  <router-link
    v-if="loginUser.role_id === 1"
    :to="`/expert/edit/${expertInformation.user_id}`"
    class="expertCard"
    :class="{
      'blocked-user':
        expertInformation.user && expertInformation.user.is_blocked,
    }"
  >
    <div>
      <div class="expertCard__expert">
        <div class="expertCard__expertInformation">
          <avatar
            width="80px"
            height="80px"
            user-role="experts"
            :user-code="expertInformation.code"
            :image-name="expertInformation.image"
            size="100"
          />
          <div class="expertCard__expertInformation-container">
            <div class="expertCard__expertName">
              <short-name
                :name="
                  expertInformation.first_name +
                  ' ' +
                  expertInformation.last_name
                "
              />
            </div>
            <div class="expertCard__expertPosition">
              {{ expertInformation.position }}
            </div>
          </div>
        </div>
        <div class="expertCard__expertLang-wrapper">
          <div
            v-for="language in expertInformation.languages"
            :key="language.id"
            class="expertCard__expertLang"
          >
            {{ language.code }}
          </div>
        </div>
      </div>
      <div>
        <div class="expertCard__expertServices-wrapper">
          <div
            v-for="service in expertInformation.services"
            :key="service.id"
            class="expertCard__expertLang expertCard__services"
          >
            <div v-if="service.name">{{ service.name }}</div>
            <div v-else>{{ service.translations[0].name }}</div>
          </div>
        </div>
      </div>
      <div class="expertCard__shortInformation">
        <div v-if="expertInformation.translations">
          <span
            v-html="expertInformation.translations[0].short_biography"
          ></span>
        </div>
        <div v-if="expertInformation.short_biography">
          <span v-html="expertInformation.short_biography"></span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <expert-cart-arrow />
    </div>
  </router-link>
  <router-link
    v-if="loginUser.role_id === 4"
    :to="/expert/ + expertInformation.code"
    class="expertCard"
    :class="{
      'blocked-user':
        expertInformation.user && expertInformation.user.is_blocked,
    }"
  >
    <div>
      <div class="expertCard__expert">
        <div class="expertCard__expertInformation">
          <avatar
            width="80px"
            height="80px"
            user-role="experts"
            :user-code="expertInformation.code"
            size="100"
            :image-name="expertInformation.image"
          />
          <div class="expertCard__expertInformation-container">
            <div class="expertCard__expertName">
              <short-name
                :name="
                  expertInformation.first_name +
                  ' ' +
                  expertInformation.last_name
                "
              />
            </div>
            <div class="expertCard__expertPosition">
              {{ expertInformation.position }}
            </div>
          </div>
        </div>
        <div class="expertCard__expertLang-wrapper">
          <div
            v-for="language in expertInformation.languages"
            :key="language.id"
            class="expertCard__expertLang"
          >
            {{ language.code }}
          </div>
        </div>
      </div>
      <div>
        <div class="expertCard__expertServices-wrapper">
          <div
            v-for="service in expertInformation.services"
            :key="service.id"
            class="expertCard__expertLang expertCard__services"
          >
            <div v-if="service.name">{{ service.name }}</div>
            <div v-else>{{ service.translations[0].name }}</div>
          </div>
        </div>
      </div>
      <div class="expertCard__shortInformation">
        <div v-if="expertInformation.translations">
          <span
            v-html="expertInformation.translations[0].short_biography"
          ></span>
        </div>
        <div v-if="expertInformation.short_biography">
          <span v-html="expertInformation.short_biography"></span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <base-button
        class="expertCard__btn"
        :calendar="true"
        margin-left="0"
        link=""
        @click="addExpertToBook"
      >
        {{ $t("bookExpertBtn") }}
      </base-button>
      <div class="expertCard__link">
        {{ $t("readMoreBtn") }}
        <Arrow width="12px" height="12px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import BaseButton from "@/components/UI/buttons/BaseButton";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import ShortName from "@/components/components-helpers/ShortName";
import { mapGetters } from "vuex";
import ExpertCartArrow from "@/components/UI/icons/arrows/ExpertCartArrow";

export default {
  props: {
    expertInformation: Object,
  },
  name: "ExpertCard",
  components: { ExpertCartArrow, ShortName, Avatar, BaseButton, Arrow },
  methods: {
    addExpertToBook() {
      this.$store.commit("SET_BOOK_DATA_EXPERT", this.expertInformation);
      this.$router.push("/calendar");
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
