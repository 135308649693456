<template>
  {{ value }}
</template>

<script>
import { format, formatDistanceToNow } from "date-fns";

export default {
  props: {
    data: String,
  },
  name: "DataLastSeen",
  computed: {
    value() {
      let i = !!this.data
        ? formatDistanceToNow(new Date(this.data))
        : this.$t("neverSeen");
      if (
        i.replace(/[^a-z]/gi, "") === "hours" ||
        i.replace(/[^a-z]/gi, "") === "minutes"
      ) {
        return formatDistanceToNow(new Date(this.data));
      } else {
        return this.data
          ? format(new Date(this.data), "dd MMM yyyy")
          : this.$t("neverSeen");
      }
    },
  },
};
</script>

<style></style>
