<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 36L36 4M36 4H10M36 4V30" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: "ExpertCartArrow"
}
</script>

<style>

</style>
