<template>
    <svg :class="{'active_state':active}" width="17" height="17" viewBox="0 0 17 17" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="7" height="7" rx="1" fill="black"/>
        <rect x="0.5" y="9.5" width="7" height="7" rx="1" fill="black"/>
        <rect x="9.5" y="0.5" width="7" height="7" rx="1" fill="black"/>
        <rect x="9.5" y="9.5" width="7" height="7" rx="1" fill="black"/>
    </svg>
</template>

<script>
export default {
    props: {
        active: Boolean
    },
    name: "CartViewIcon"
}
</script>

<style>

</style>
