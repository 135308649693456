<template>
  <main-layout>
    <div class="experts">
      <div class="row align-items-center justify-content-between">
        <h1 v-if="loginUser.role_id !== 1" class="w-auto m-0">
          {{ $t("expertsPageTitle") }}
        </h1>
        <h1 v-else class="w-auto">{{ $t("expertsAdminTitle") }}</h1>
        <div class="experts__searchInput">
          <search-input
            v-model="search"
            :search-function="searchExperts"
            @keyup.enter="searchExperts"
            :placeholder="$t('expertSearchInput')"
          />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <div>
          <div class="experts__filterTitle">{{ $t("expertsPageFilter") }}</div>
          <div class="experts__filterButtonsContainer">
            <vue-multiselect
              class="servicesPage__select"
              :placeholder="$t('selectPlaceholder')"
              :show-labels="false"
              :searchable="true"
              label="name"
              track-by="name"
              v-model="filterServices"
              :options="services"
            />
            <div @click="clearFilter()" class="experts__filterReset">
              <reset-icon />
              {{ $t("resetFilter") }}
            </div>
          </div>
        </div>
        <div
          v-if="loginUser.role_id === 1"
          class="mt-3 d-flex justify-content-end align-items-center"
        >
          <div class="me-10">
            {{ $t("view") }}
          </div>
          <div class="me-10 cursor-pointer" @click="changeView('table')">
            <table-view-icon :active="view.table" />
          </div>
          <div class="me-10 cursor-pointer" @click="changeView('cart')">
            <cart-view-icon :active="view.cart" />
          </div>
        </div>
      </div>
      <div v-if="experts.length > 0">
        <div v-if="view.cart" class="row experts__cards">
          <div
            v-for="expert in experts"
            :key="expert.user_id"
            class="col-12 col-lg-6 mb-20"
          >
            <expert-card :expert-information="expert" />
          </div>
        </div>
        <div v-if="loginUser.role_id === 1 && view.table">
          <div class="row transactionsPage__tableTitles">
            <div class="col-4">{{ $t("filterByExpert") }}</div>
            <div class="col-2">{{ $t("filterByPosition") }}</div>
            <div class="col-2">{{ $t("filterByLastSeen") }}</div>
            <div class="col-3">{{ $t("filterByService") }}</div>
          </div>
          <expert-table
            v-for="expert in experts"
            :key="expert.user_id"
            :expert-information="expert"
          />
        </div>
        <div v-if="experts.length === viewLimit" class="w-max-content m-auto">
          <base-button @click="loadMore()" :load="true"
            >{{ $t("loadMore") }}
          </base-button>
        </div>
      </div>
      <div v-else class="mt-30">
        <h5>{{ $t("noFoundResult") }}</h5>
        <p class="mt-10">{{ $t("tryAnother") }}</p>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import ExpertCard from "@/components/elements/card/ExpertCard";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import ShortName from "@/components/components-helpers/ShortName";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import VueMultiselect from "vue-multiselect";
import ExpertTable from "@/components/elements/card/ExpertTable";
import { mapGetters } from "vuex";

export default {
  name: "ExpertsPage",
  components: {
    ExpertTable,
    TableViewIcon,
    CartViewIcon,
    ShortName,
    MainLayout,
    SearchInput,
    ExpertCard,
    BaseButton,
    ResetIcon,
    VueMultiselect,
  },
  data() {
    return {
      view: {
        cart: false,
        table: true,
      },
      filter: [],
      experts: [],
      services: [],
      filterServices: [],
      filterValue: [],
      viewLimit: 10,
      viewOffset: 0,
      search: "",
    };
  },
  mounted() {
    if (this.loginUser.role_id === 4) this.changeView("cart");
    this.getExperts(null, null, this.viewLimit);
    this.getServices();
  },
  methods: {
    changeView(view) {
      for (let i in this.view) {
        this.view[i] = i === view;
      }
    },
    loadMore() {
      this.viewLimit += 10;
      this.getExperts(this.filterValue, this.viewOffset, this.viewLimit);
    },
    clearFilter() {
      this.filter = [];
      this.search = "";
      this.filterServices = [];
      this.getExperts(this.filterServices, this.viewOffset, this.viewLimit);
    },
    getExperts() {
      let services = this.filterValue ? this.filterValue : null;
      let offset = this.viewOffset ? this.viewOffset : null;
      let limit = this.viewLimit ? this.viewLimit : null;
      let search = this.search ? this.search : null;
      this.$http
        .get("/experts", {
          params: {
            notActivated: this.loginUser.role_id !== 4,
            blocked: this.loginUser.role_id !== 4,
            service: services,
            offset: offset,
            limit: limit,
            search: search,
          },
        })
        .then(({ data }) => {
          this.experts = data.body;
        });
    },
    getServices() {
      this.$http.get("/services").then(({ data }) => {
        this.services = data.body;
      });
    },
    searchExperts() {
      this.getExperts();
    },
  },
  watch: {
    filterServices: {
      deep: true,
      handler(newCount) {
        this.filterValue = newCount ? newCount.id : null;
        this.getExperts();
      },
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
