<template>
    <svg :class="{'active_state':active}" width="17" height="14" viewBox="0 0 17 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="16" height="3.5" fill="black"/>
        <rect x="0.5" y="5.5" width="16" height="3.5" fill="black"/>
        <rect x="0.5" y="10.5" width="16" height="3.5" fill="black"/>
    </svg>

</template>

<script>
export default {
    props: {
        active: Boolean
    },
    name: "TableViewIcon"
}
</script>

<style>

</style>
