<template>
  <router-link
    :to="
      currentUser.role_id === 1
        ? '/expert/edit/' + expertInformation.user_id
        : '/expert/' + expertInformation.code
    "
    :class="{ 'blocked-user': expertInformation.user.is_blocked }"
  >
    <div class="row expertTable">
      <div class="col-4 d-flex align-items-center">
        <avatar
          class="me-10"
          width="60px"
          height="60px"
          user-role="experts"
          size="100"
          :user-code="expertInformation.code"
          :image-name="expertInformation.image"
        />
        <div>
          <div class="expertTable__name">
            <!--            <short-name :name="expertInformation.first_name + ' ' + expertInformation.last_name"/>-->
            {{
              `${expertInformation.first_name} ${expertInformation.last_name}`
            }}
          </div>
          <div class="mt-1 expertTable__text">{{ expertInformation.code }}</div>
        </div>
      </div>
      <div class="col-2 expertTable__name">
        {{ expertInformation.translations[0].position }}
      </div>
      <div class="col-2 expertTable__text">
        <data-last-seen :data="expertInformation.user.profiles[0].last_seen" />
      </div>
      <div
        v-if="expertInformation.services.length > 0"
        class="col-3 d-flex flex-wrap"
      >
        <div
          class="me-2"
          v-for="service in expertInformation.services"
          :key="service.id"
        >
          {{ service.translations[0].name }}
        </div>
      </div>
      <div v-else class="col-3"></div>
      <div class="col-1 text-end">
        <arrow height="16px" width="16px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DataLastSeen from "@/components/components-helpers/DataLastSeen";
import { mapGetters } from "vuex";

export default {
  components: { DataLastSeen, Arrow, ShortName, Avatar },
  props: {
    expertInformation: Object,
  },
  name: "ExpertTable",
  computed: {
    ...mapGetters({
      currentUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
